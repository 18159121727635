<template>
  <div class="openQuestionContainer">
    <b-row class="py-3">
      <b-col md="12" class="mb-3">
        <h3>Homework time</h3>
      </b-col>
      <!-- {{ this.data }} -->
      <b-col class="mb-3" md="12" v-for="(subTopic,index) in data.subTopics" :key="subTopic.id">
        <h4 class="mb-2">{{subTopic.open_questions}}</h4>
        <p>Enter your response below</p>
        <b-form-textarea
          id="textarea-rows"
          placeholder="Type here...."
          rows="8"
          class="rounded-lg"
          v-model="form[index].answer"
        ></b-form-textarea>
      </b-col>
    </b-row>
    <b-row class="submitContainer">
      <div class="w-100 d-flex justify-content-end p-2">
        <!-- v-if=" index+1 != data.subTopics[0].MCQ.length" -->
        <button
            @click="submit"
            :class="
                hasAnyAnswerFilled
                ? 'btn btn-primary rounded-pill px-4'
                : 'btn btn-secondary cursor-disabled rounded-pill px-4'
            "
        >
            Submit
        </button>
      </div>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import serverURL from "@/constants";
import VueApexCharts from "vue-apexcharts";

import {
  BIcon,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BAlert,
  BFormTextarea
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BSpinner,
    BCardBody,
    BTab,
    BTabs,
    BAlert,
    BIcon,
    FeatherIcon,
    BFormTextarea
  },
  props: {
    data: { default: null },
  },
  computed: {
    ...mapGetters({
      item: "topics/item",
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    hasAnyAnswerFilled() {
      return this.form.some(item => item.answer && item.answer.trim() !== '');
    }
  },
  data() {
    return {
      selectedOption: null,
      form: this.data.subTopics.map(subTopic => ({
        answer: '',
        sub_topic_id: subTopic.id, 
        topic_id: this.$route.params.id
      }))
    };
  },
  methods: {
    checkQuestion(index){
        // this.currentQuestion = this.data.subTopics[0].MCQ[this.index]
    },
    select(option) {
      this.selectedOption = option;
    },
    selectedClass(option) {
      if (this.selectedOption && option.id == this.selectedOption.id)
        return "border border-white rounded-lg w-100 p-2 cursor-pointer selected";
      return "border border-white rounded-lg w-100 p-2 cursor-pointer";
    },
    submit(){
        try {
          this.$store.dispatch('lessonPlans/answerOpenQuestions', this.form).then((resp)=>{
            this.$router.push({name: 'finish_lesson', params:{
              result: 100,
              module_id: resp.module_id,
              current_id: this.$route.params.id,
              next_id: resp.next_id
            }})
          })
        } catch (error) {
            if (error.response) {
                this.$swal({
                    icon: 'error',
                    title: `<h4>${error.response.data.error}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: '#E84185',
                    allowOutsideClick: true
                });
            }
        }
    }
  },
  mounted(){
    this.checkQuestion()
  }
};
</script>

<style scoped>
.selected {
  border: 1px solid #e84185 !important;
}
.openQuestionContainer{
  position: relative;
}
.submitContainer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  background: #0b0b0b;
}
</style>