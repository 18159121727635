<template>
  <div>
    <b-row v-for="subTopic in data.subTopics" :key="subTopic.id">
      <b-card
        :class="
              $store.state.appConfig.layout.skin == 'dark'
                  ? 'bg-custom-card-dark w-100'
                  : ''
              "
      >
        <div
          :class="
                  $store.state.appConfig.layout.skin == 'dark' ? 'text-white' : ''
              "
        >
          <div 
            :class="
                $store.state.appConfig.layout.skin == 'dark' ? 'w-100 dark-card' : 'w-100'
            " 
          >
            <quill-editor
              v-model="item.subTopics[0].text"
              :options="bubbleOption"
              class="custom-quill"
              
            >
            </quill-editor>
            <!-- <span v-html="item.subTopics[0].text"></span> -->
          </div>
        </div>
        <b-row>
          <b-col md="12" class="d-flex justify-content-center">
            <button class="btn btn-primary rounded-pill mt-3 px-4" @click="finish">Finish</button>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import serverURL from "@/constants";
import VueApexCharts from "vue-apexcharts";
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
import { quillEditor } from 'vue-quill-editor'
// import { codeSnow } from './code'
import {
  BIcon,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BAlert
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BSpinner,
    BCardBody,
    BTab,
    BTabs,
    BAlert,
    FeatherIcon,
    quillEditor,
    BIcon
  },
  props: {
    data: { default: null }
  },
  data() {
    return {
      selectedOption: null,
      answers: [],
      bubbleOption: {
        modules: {
          toolbar: false,
        },
        theme: 'bubble',
      },
      currentQuestion: null,
      index: 0,
      type: 2
    };
  },
  computed: {
    ...mapGetters({
      item: "topics/item"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    }
  },
  methods: {
    finish() {
      const payload = {
        topic_id: this.$route.params.id
      };
      this.$store.dispatch("lessonPlans/finishTopic", payload).then(resp => {
        this.$router.push({name: 'finish_lesson', params:{
          result: 100,
          module_id: resp.module_id,
          current_id: this.$route.params.id,
          next_id: resp.next_id
        }})
      });
    }
  },
  mounted() {
    this.checkQuestion();
  }
};
</script>
  
<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
.selected {
  border: 1px solid #e84185 !important;
}
</style>